export const menuItems = [
    {
        label: 'Menu',
        isTitle: true,
    },
    {
        label: 'Dashboard',
        icon: 'uil-home-alt',
        /* badge: {
            variant: "primary",
            text: "01"
        }, */
        link: '/',
        //permissions: 'DASHBOARD/VIEW', //removed as all users should land on the dashboard
    },
    {
        label: 'Clients',
        icon: 'uil-user',
        link: '/clients/list',
        permissions: 'CLIENTS',
        subItems: [
            {
                label: 'All Clients',
                link: '/clients/list',
                permissions: 'CLIENTS',
            },
            // {
            //     label: 'Client Groups',
            //     link: '/clients/groups/list',
            //     permissions: 'CLIENTS/GROUPS',
            // },
            {
                label: 'USSD Clients',
                link: '/clients/ussd/clients',
                permissions: 'CLIENTS/USSD',
            },
            {
                label: 'USSD Dashboard',
                link: '/clients/ussd/dashboard',
                permissions: 'CLIENTS/USSD',
            },
            // {
            //     label: 'Payments',
            //     link: '/clients/payments/',
            //     permissions: 'CLIENTS',
            // },
            // {
            //     label: 'Phone Book',
            //     link: '/clients/phone-book',
            //     permissions: 'CLIENTS',
            // },
        ],
    },
    {
        label: 'Communications',
        icon: 'uil-envelope',
        link: '/comms/email/inbox/folderType/',
        permissions: 'COMMUNICATIONS',
        subItems: [
            {
                label: 'Email',
                link: '/comms/email/inbox/folderType/',
                permissions: 'COMMUNICATIONS/EMAIL',
            },
            {
                label: 'Bulk SMS Sending',
                link: '/comms/sms',
                permissions: 'COMMUNICATIONS/SCHEDULED',
            },
            // {
            //     label: 'Chat',
            //     link: '/comms/chat',
            //     //permissions: 'COMMUNICATIONS/CHAT',
            // },
            // {
            //     label: 'Scheduled',
            //     link: '/comms/scheduled',
            //     permissions: 'COMMUNICATIONS',
            //     permissions: 'COMMUNICATIONS/SCHEDULED',
            // },
        ],
    },
    // {
    //     label: 'Loan Accounts',
    //     icon: 'uil-coins',
    //     link: '/coming-soon',
    //     //permissions: 'LOAN ACCOUNTS',
    // },
    // {
    //     label: 'Deposit Accounts',
    //     icon: 'uil-money-bill',
    //     link: '/coming-soon',
    //     //permissions: 'DEPOSIT ACCOUNTS',
    // },
    // {
    //     label: 'Products',
    //     icon: 'uil-calculator',
    //     permissions: 'PRODUCTS',
    //     hideWhenOffline: false,
    //     subItems: [
    //         {
    //             label: 'All Products',
    //             link: '/products/',
    //             permissions: 'PRODUCTS',
    //         },
    //         ]
    // },
    {
        label: 'Sales',
        icon: 'uil-calculator',
        permissions: 'SALES',
        hideWhenOffline: false,
        subItems: [
            {
                label: 'My Work',
                link: '/agents/landingpage',
                permissions: 'SALES/AGENTS/MYWORK',
            },
            {
                label: 'Sales Teams',
                link: '/sales/teams',
                permissions: 'SALES/TEAMS',
            },
            {
                label: 'Sales Targets',
                link: '/sales/targets',
                permissions: 'SALES/TARGETS',
            },
            {
                label: 'Leads',
                link: '/sales/leads',
                permissions: 'SALES/LEADS',
            },
            // {
            //     label: 'Cases',
            //     link: '/sales/cases',
            //     //permissions: 'SALES/CASES',
            // },
            {
                label: 'Activities',
                link: '/sales/activities',
                permissions: ['SALES/ACTIVITIES', 'SALES/ACTIVITIES/ACTIVITIES'],
                subItems: [
                    {
                        label: 'All activities',
                        link: '/sales/activities',
                        permissions: 'SALES/ACTIVITIES/ACTIVITIES',
                    },
                    {
                        label: 'Agent Activity',
                        link: '/sales/activities/agents',
                        permissions: 'SALES/ACTIVITIES/ACTIVITIES',
                    },
                    {
                        label: 'Agent Actions',
                        link: '/sales/agents/actions',
                        permissions: 'SALES/AGENTS/HISTORY',
                    },
                    {
                        label: 'Calendar',
                        link: '/sales/activities/calendar',
                        permissions: 'SALES/ACTIVITIES/CALENDAR',
                    },
                    {
                        label: 'Route Plans',
                        link: '/sales/activities/activity-plan',
                        permissions: 'SALES/ACTIVITIES/ACTIVITY_PLANS',
                    },
                    {
                        label: 'Day overview',
                        link: '/sales/activities/activity-plan/day-overview',
                        permissions:
                            'SALES/ACTIVITIES/ACTIVITY_PLANS/DAY_OVERVIEW/VIEW',
                    },
                ],
            },

            {
                label: 'Affordability',
                link: '/sales/calculators/cs-affordability',
                permissions: 'SALES/AFFORDABILITY',
                subItems: [
                    {
                        label: 'CS Affordability',
                        link: '/sales/calculators/cs-affordability',
                        permissions: 'SALES/AFFORDABILITY/CS',
                    },
                    {
                        label: 'SME Affordability',
                        link: '/sales/calculators/sme-affordability',
                        permissions: 'SALES/AFFORDABILITY/SME',
                    },
                    {
                        label: 'LBF Affordability',
                        link: '/sales/calculators/lbf-affordability',
                        permissions: 'SALES/AFFORDABILITY/LBF',
                    },
                    {
                        label: 'LBF Insurance Premium',
                        link: '/sales/calculators/lbf-premium',
                        permissions: 'SALES/AFFORDABILITY/LBF',
                    }
                ],
            },
        ],
    },
    // {
    //     label: 'Reports',
    //     icon: 'uil-dashboard',
    //     permissions: 'REPORTING',
    //     subItems: [
    //         {
    //             label: 'Standard Reports',
    //             link: '/reports/standard',
    //             permissions: 'ADMIN/REPORTS',
    //         },
    //         {
    //             label: 'User Login Report',
    //             link: '/reports/standard/userLoginReport',
    //             permissions: 'ADMIN/REPORTS',
    //         },
    //         {
    //             label: 'Scheduled Reports',
    //             link: '/scheduledReport/index',
    //             permissions: 'ADMIN/REPORTS',
    //         },
    //         {
    //             label: 'Report Categories',
    //             link: '/reports/categories',
    //             permissions: 'REPORTING/CATEGORIES',
    //         },
    //         {
    //             label: 'Custom Reports',
    //             link: '/reports',
    //             permissions: 'REPORTING/CUSTOM_REPORTS',
    //         },

    //     ],
    // },
    // {
    //     label: 'Complaints',
    //     icon: 'uil-comment',
    //     permissions: 'COMPLAINTS',
    //     subItems: [
    //         {
    //             label: 'List Complaints',
    //             link: '/complaints/',
    //             permissions: 'COMPLAINTS',
    //         },
    //     ],
    // },
    // Final Menu Item must always be Administration
    {
        label: 'Administration',
        icon: 'uil-cog',
        permissions: 'ADMIN',
        hideWhenOffline: true,
        subItems: [
            // {
            //     label: 'Settings',
            //     link: '/admin/settings',
            //     permissions: 'ADMIN/SETTINGS',
            // },
            // {
            //     label: 'Business Processes',
            //     link: '/admin/business-processes/list',
            //     permissions: 'ADMIN/BUSINESS_PROCESSES',
            // },
            {
                label: 'Locations',
                permissions: 'ADMIN/LOCATIONS',
                link: '/admin/locations',

            },
            {
                label: 'Users',
                permissions: 'ADMIN/USERS',
                link: '/admin/users/list',
                subItems: [
                    {
                        label: 'All Users',
                        link: '/admin/users/list',
                        permissions: 'ADMIN/USERS',
                    },
                    {
                        label: 'Deactivated Users',
                        link: '/admin/users/list-deleted',
                        permissions: 'ADMIN/USERS/DELETE',
                    },
                    // {
                    //     label: 'All API Keys',
                    //     link: '/admin/api-users/list',
                    //     permissions: 'ADMIN/API_USERS/LIST',
                    // },
                ],
            },
            {
                label: 'Roles',
                link: '/admin/roles',
                permissions: 'ADMIN/ROLES',
                subItems: [
                    {
                        label: 'All roles',
                        link: '/admin/roles/list',
                        permissions: 'ADMIN/ROLES',
                    },
                ],
            },
            // {
            //     label: 'Permissions',
            //     link: '/admin/permissions',
            //     permissions: 'ADMIN/PERMISSIONS',
            // },
            // {
            //     label: 'Sales',
            //     link: '/admin/sales/',
            //     permissions: 'ADMIN/SALES',
            //     subItems: [
            //         {
            //             label: 'Pipelines',
            //             link: '/admin/sales/pipelines/list',
            //             permissions: 'ADMIN/SALES/LIST',
            //         },
            //     ],
            // },
            // {
            //     label: 'Templates',
            //     link: '/admin/template/list',
            //     permissions: 'ADMIN/TEMPLATES',
            //     subItems: [
            //         {
            //             label: 'All Templates',
            //             link: '/admin/template/list',
            //             permissions: 'ADMIN/TEMPLATES',
            //         },
            //     ],
            // },
            // {
            //     label: 'Data Models',
            //     link: '/admin/data-models/list',
            //     permissions: 'ADMIN/DATA_MODELS',
            // },
            // {
            //     label: 'Web Forms',
            //     link: '/admin/web-form/list',
            //     permissions: 'ADMIN',
            // },
            {
                label: 'Tenants',
                permissions: 'ADMIN/TENANTS',
                link: '/admin/tenants/list',
                subItems: [
                    {
                        label: 'Tenants',
                        link: '/admin/tenants/list',
                        permissions: 'ADMIN/TENANTS',
                    },
                ],
            },
            // {
            //     label: 'IP Addresses',
            //     link: '/admin/ip-addresses/list',
            //     permissions: 'ADMIN/IP_ADDRESSES',
            // },
        ],
    },
    {
        label: 'Install',
        icon: 'uil-mobile-android',
        class: 'install-app',
        link: '#',
    },
];
